<template>
  <div class="reward container">
      <div class="title">{{$t('app.invest.myReward')}}</div>
      <div class="content">
          <div class="header">
              <section>
                  <div>{{$t('app.invest.ReferralRewards')}}</div>
                  <span>{{this.reward.bind | numFilter5}} <img src="../../assets/img/usdt-icon.png"/></span>
              </section>
              <div>
                  <div>
                      <div>{{$t('app.invest.ClickReward')}}</div>
                      <span>{{this.reward.seePoint | numFilter5}} <img src="../../assets/img/usdt-icon.png"/></span>
                  </div>
                  <div>
                      <div>{{$t('app.invest.InteractionRewards')}}</div>
                      <span>{{this.reward.interaction | numFilter5}} <img src="../../assets/img/usdt-icon.png"/></span>
                  </div>
                  <div>
                      <div>{{$t('app.invest.MatchReward')}}</div>
                      <span>{{this.reward.colliding | numFilter5}} <img src="../../assets/img/usdt-icon.png"/></span>
                  </div>
                  <div>
                      <div>{{$t('app.invest.ManagementRewards')}}</div>
                      <span>{{this.reward.manage | numFilter5}} <img src="../../assets/img/usdt-icon.png"/></span>
                  </div>
              </div>
              <button @click="getRewardMsd">{{$t('app.invest.OneClickCollection')}}</button>
          </div>
          <div class="invite">
            <div class="myID">
              <span>{{$t('_bind_._myID_')}}: {{myID || '--'}}</span>
                <i @click="handleCopyClick"></i>
            </div>
            <div class="tabs">
              <h4 :class="{active:activeTab == 0}" @click="activeTab = 0">{{$t('app.invest.DirectSubordinate')}}</h4>
              <h4 :class="{active:activeTab == 1}" @click="activeTab = 1">{{$t('_bind_._jdXJ_')}}</h4>
              <h4 :class="{active:activeTab == 2}" @click="activeTab = 2">{{ $t('news.level')}}</h4>
            </div>
            <div class="list" v-if="activeTab == 0">
              <header>
                <div>
                    <div>{{$t('app.invest.Address')}}</div>
                    <span>{{ $t('news.levelamount')}}</span>
                    <span>{{$t('app.invest.CommunityPerformance')}}</span>
                    <span>{{ $t('news.level')}}</span>
                </div>
              </header>
              <main>
                <div v-for="item in showList" :key="item.address">
                    <div>{{item.address | addressCharacter}}</div>
                    <span>{{Number(item.investTotal).toFixed(0)}}</span>
                    <span>{{Number(item.data).toFixed(0) | numFilter5}}</span>
                    <span v-if="item.levels==-1">{{ $t('news.vip')}}</span>
                    <span v-else-if="item.levels==0">{{ $t('news.start')}}{{ $t('news.star')}}</span>
                    <span v-else>{{item.levels}}</span>
                </div>
              </main>
              <div class="empty" v-if="showList.length <= 0">
                <div class="empty-bg"></div>
                <div class="tips">{{ $t('com.empty') }}</div>
              </div>
            </div>

            <div v-else-if="activeTab==1" class="seepoint" style="margin-top: 0.5rem;">
                <div @click="getSubById(subList2.users.seeReferrer,subList2.users.id == myID)">
                    <div>ID:{{subList2.users.id}}</div>
                    <div>{{ $t('news.allLevelamount')}}:{{subList2.users.investTotal | numFilter5}}</div>
                    <div>{{ $t('news.totalPerformance')}}:{{subList2.users.achievement | numFilter5}}</div>
                </div>
                <section>
                    <div v-for="item in subList2.seePoint" :key="item.id" @click="getSubById(item.id)">
                        <div>ID:{{item.id}}</div>
                        <div>{{ $t('news.allLevelamount')}}:{{item.investTotal | numFilter5}}</div>
                        <div>{{ $t('news.totalPerformance')}}:{{item.achievement | numFilter5}}</div>
                    </div>
                </section>
            </div>

            <div class="list" v-else-if="activeTab == 2">

              <div style="margin-top: 0.25rem;text-align: center;margin-bottom: 0.25rem;" v-if="myLevel==-1"> {{ $t('news.nowlevel')}}： {{ $t('news.vip')}}</div>
              <div style="margin-top: 0.25rem;text-align: center;margin-bottom: 0.25rem;" v-if="myLevel==0"> {{ $t('news.nowlevel')}}： {{ $t('news.start')}}{{ $t('news.star')}}</div>
              <div style="margin-top: 0.25rem;text-align: center;margin-bottom: 0.25rem;" v-else-if="myLevel==1"> {{ $t('news.nowlevel')}}： 一 {{ $t('news.star')}}</div>
              <div style="margin-top: 0.25rem;text-align: center;margin-bottom: 0.25rem;" v-else-if="myLevel==2"> {{ $t('news.nowlevel')}}： 二 {{ $t('news.star')}}</div>
              <div style="margin-top: 0.25rem;text-align: center;margin-bottom: 0.25rem;" v-else-if="myLevel==3"> {{ $t('news.nowlevel')}}： 三 {{ $t('news.star')}}</div>
              <header>
                <div>
                  <div>ID</div>
                  <span>{{ $t('news.nowlevel')}}</span>
                  <span>{{$t('news.levelamount')}}</span>
                  <span>A{{ $t('news.area')}}</span>
                  <span>B{{ $t('news.area')}}</span>
                </div>
              </header>
              <main>
                <div >
                  <div>{{subList3.users.id }}</div>
                  <span>{{subList3.users.investTotal.toFixed(0)}}</span>
                  <span>{{subList3.users.achievement.toFixed(0) }}</span>
                  <span v-if="subList3.seePoint.length>0">{{Number(subList3.seePoint[0].achievement).toFixed(0) }}</span>
                  <span v-else>0</span>
                  <span v-if="subList3.seePoint.length>1">{{Number(subList3.seePoint[1].achievement).toFixed(0) }}</span>
                  <span v-else>0</span>
<!--                  <span>{{item.levels}}</span>-->
                </div>
              </main>
<!--              <div class="empty" v-if="subList2.seePoint.length <= 0">-->
<!--                <div class="empty-bg"></div>-->
<!--                <div class="tips">{{ $t('com.empty') }}</div>-->
<!--              </div>-->
            </div>

            <p></p>
<!--             <a href="javascript:void(0)">查看更多></a>-->
          </div>
      </div>
  </div>
</template>

  <script>
  import { ethers } from 'ethers'
  import ERC20 from '../../contracts/ERC20.json'
  import Fare from '../../contracts/Fare.json'
  import { get, post } from '../../utlis/axios'
  import { initSigner } from '../../utlis/index'
  export default {
    data () {
      return {
        contracts: {},
        address: '',
        reward:{
          bind:0, //推荐奖励
          seePoint:0, //见点奖
          interaction:0, //互动奖
          colliding:0, //对碰奖
          manage:0, //管理奖
        },
        min: 300,           // 步进值
        UNumber: 300,      // USDT数量
        total: 5190,      // 总可购买量
        limit: 0,         // 剩余可购买量
        balance: 0,
        allowance: 0,
        myID:0,
        myLevel:0,
        activeTab:0,
        loading:{
          getReward:false,
        },
        subList:[],
        subList2:{
          users:{},
          seePoint:[],
        },
        subList3:{
          users:{},
          seePoint:[],
        },
      }
    },
    mounted () {
        let tries = 0,_this = this;
        setTimeout(function initTimer() {
            //@ts-ignore
            if (!window.ethereum && ++tries < 20) return setTimeout(initTimer, 50);
            else _this.init()
        })
    },
    computed:{
      showList(){
        if(this.activeTab == 0) return this.subList
        else if(this.activeTab == 1) return this.subList2
        else if(this.activeTab == 2) return this.subList3
        else return []
      }
    },
    beforeDestroy () {
      clearInterval(this.initTimer)
    },
    methods: {
      async init () {
        let { address, provider, signer } = await initSigner()
        this.address = address
        this.contracts.USDT = new ethers.Contract(this.$common.USDT, ERC20, provider).connect(signer)
        this.contracts.Fare = new ethers.Contract(this.$common.Fare, Fare, provider).connect(signer)
        this.getData()
        this.getSubList()
        window.contracts = this.contracts;
        clearInterval(this.initTimer)
        this.initTimer = setInterval(() => {
          this.getData()
        }, 600000);
      },
      getSubList(){
        get('/getSubordAchievement',{address:this.address}).then(res => {
            let arr = []
            res && res.forEach((item) => {
                arr.push({
                  address:item.address,
                  data:item.achievement,
                  investTotal:item.investTotal,
                  levels:item.levels
                })
            });
            this.subList = arr;
        })
      },


      async getSubById(id,flag = false){
        if(flag) return;
          let res = await get('/getSeePointAchievement',{id:id})
          this.$set(this.subList2,'users',res.users || {})
          this.$set(this.subList2,'seePoint',res.seePoint || [])
      },

      async getSubById2(id,flag = false){
        if(flag) return;
        let res = await get('/getlevelSeePointAchievement',{id:id})
        this.$set(this.subList3,'users',res.users || {})
        this.$set(this.subList3,'seePoint',res.seePoint || [])

        // for (let  i = 0;i<this.subList3.seePoint.length;i++)
        // {
        //     let  seePoint = this.subList3.seePoint[i];
        //     console.log(seePoint.id);
        //     let res = await get('/getlevelSeePointAchievement',{id:id})
        //     console.log(res);
        //     // this.$set(this.subList2,'users',res.users || {})
        //     if (res.seePoint.length>0)
        //     {
        //       seePoint.amount = res.seePoint[0].achievement;
        //     }
        //     if (res.seePoint.length>1)
        //     {
        //       seePoint.bmount = res.seePoint[1].achievement;
        //     }
        // }
      },



      async getData () {
        if (!this.address) return false
        get('/myID',{address:this.address}).then(res => {
            this.myID = res;
            this.getSubById(this.myID)
            this.getSubById2(this.myID)
        })
        get('/getLevel',{address:this.address}).then(res => {
          this.myLevel = res;

        })
        get('/getBindReward',{address:this.address}).then(res => {
            this.reward.bind = res.bind || 0
            this.reward.seePoint = res.seePoint || 0
            this.reward.interaction = res.interaction || 0
            this.reward.colliding = res.colliding || 0
            this.reward.manage = res.manage || 0
        })
      },
    // 复制邀请链接
      handleCopyClick() {
        if(!this.myID) return;
        let input = document.createElement("input");
        input.value = this.myID;
        document.body.appendChild(input);
        input.select();
        document.execCommand("Copy");
        document.body.removeChild(input);
        this.$message.success(this.$t('app.cooperation.copyCG'))
      },
      getRewardMsd(){
          if(this.loading.getReward) return;
          let keys = Object.keys(this.reward)
          let isZero = true;
          for(let i = 0 ; i < keys.length ; i++){
              let key = keys[i];
              if(Number(this.reward[key])) isZero = false;
          }
          if(isZero) return this.$message.warning(this.$t('app.amount0'))
          this.loading.getReward = true;
          post('/getReward',{address:this.address}).then(res => {
              if(res && res.sign){
                  this.contracts.Fare.getReward(res.interaction,res.bind,res.colliding,res.seePoint,res.manage,res.sign).then(async tx => {
                      await tx.wait();
                      this.getData()
                      this.loading.getReward = false
                      this.$message.success(this.$t('app.ReceiveSuccess'))
                  }).catch(e => {
                      console.error(e);
                      this.loading.getReward = false
                      // let ed = JSON.parse(JSON.stringify(e));
                      // // proxy.$message.error(t('AuthorizeSB')+' '+());
                      // this.$message.error(ed && ed.reason)
                     this.$message.error(this.$t('app.ReceiveFail'))

                  })
              }else{
                  console.error(res);
                  this.loading.getReward = false
                  this.$message.error(this.$t('app.ReceiveFail'))
              }
          }).catch(e => {
            console.error(e);
            this.loading.getReward = false
            this.$message.error(this.$t('app.ReceiveFail'))
          })
      },
      handleBuy () {
        if (!this.UNumber || this.UNumber == '-') return false
        else if (Number(this.limit) <= 0 || Number(this.limit) < this.UNumber)
          // return this.$message.warning('当前USDT剩余可购买量为' + this.limit)
          return this.$message.warning(this.$t('msg.balanceInsufficient', [this.limit]))
        let num = ethers.utils.parseUnits(String(this.UNumber))
        this.contracts.Community.swap(this.referee, num).then(async (result) => {
          await result.wait()
          this.$message.success(this.$t('msg.purchaseSucc'))
          this.getData()
        }).catch((err) => {
          console.log(err);
          this.$message.error(this.$t('msg.PurchaseFailed'))
        });
      },
      // 授权USDT
      approve () {
        const max = ethers.constants.MaxUint256
        this.contracts.USDT.approve(this.$common.Community, max).then(async (result) => {
          await result.wait()
          this.$message.success(this.$t('msg.approveSucc'))
          this.getData()
        }).catch((err) => {
          console.log(err);
          this.$message.error(this.$t('msg.approveFaile'))
        });
      },
    },
    components: {}
  }
  </script>

  <style lang="scss" scoped>
  .seepoint{
    display: flex;
    flex-direction: column;
    >div{
      width: 40%;
      margin: 0 auto;
    }
    >section{
      display: grid;
      margin-top: .5rem;
      grid-template-columns: 40% 40%;
      justify-content: space-around;
      >div{
        width: 100%;
      }
    }
  }
  .reward {
      padding: 1.4rem 0.44rem 0.4rem;
      .empty {
        font-size: 0.28rem;
        padding: 0.4rem 0;
        .empty-bg {
          width: 2.9rem;
          height: 1.87rem;
          margin: 0 auto;
          background: url('../../assets/img/empty.png') left top / 100% 100% no-repeat;
        }
        .tips {
          margin-top: 0.2rem;
          text-align: center;
        }
      }
      .title {
        width: 5.74rem;
        height: 1.66rem;
        line-height: 1.28rem;
        font-size: 0.36rem;
        text-align: center;
        font-weight: bold;
        margin: 0 auto;
        background: url('../../assets/img/web-title-bg.png') left top / 100% 100% no-repeat;
      }
      .content{
          .header{
              display: flex;
              flex-direction: column;
              background: url(../../assets/img/coin-bg.png) no-repeat;
              background-size: 100% 100%;
              padding: .6rem .6rem;
              >section{
                display: flex;
                flex-direction: column;
                text-align: center;
                font-size: .26rem;
                margin-bottom: .3rem;
              }
              >div{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                font-size: .22rem;
                >div{
                  margin-bottom: .3rem;
                  width: 40%;
                }
              }
              >div > div > span,> section > span{
                  margin-top: .25rem;
                  display: flex;
                  align-items: center;
                  font-size: .3rem;
                  color: #3E9DD2;
                  line-height: .32rem;
                  >img{
                    width: .4rem;
                    margin-left: .1rem;
                  }
              }
              > section > span{
                justify-content: center;
              }
              button{
                height: .96rem;
                background: rgba(178,133,237,0.8);
                border: 1px solid #45BBF3;
                font-size: .32rem;
                font-weight: bold;
                color: #FFFFFF;
                text-shadow: 0px 2px 1px rgba(0,0,0,0.54);
              }

          }
          .invite{
            margin-top: .3rem;
            .myID{
              margin:.3rem 0;
              display: flex;
              justify-content: space-between;
                i {
                  width: 0.38rem;
                  height: 0.38rem;
                  margin-left: 0.25rem;
                  cursor: pointer;
                  background: url("../../assets/img/web-copy.png") left top / 100%
                    100% no-repeat;
                }
            }
            .tabs{
              display: flex;
              >h4{
                margin-right: .3rem;
                color: #c4c4c4;
              }
              .active{
                color: #FFFFFF;
                font-size: 1.05em;
              }
            }
            .list{
              display: flex;
              flex-direction: column;
              margin-top: .2rem;
              header,main{
                display: flex;
                flex-direction: column;
                >div{
                  display: flex;
                  line-height:.65rem;
                  >div,>span{
                    text-align: center;
                    width: 50%;
                  }
                }
              }
              header{
                font-size: .28rem;
              }
              main{
                font-size: .26rem;
                max-height:200px;
                overflow-y: auto;
                &::-webkit-scrollbar {
                    /*滚动条整体样式*/
                    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
                    height: 100%;
                }
                &::-webkit-scrollbar-thumb {
                    /*滚动条里面小方块*/
                    border-radius: 10px;
                    background: #007aaa59;
                }
                &::-webkit-scrollbar-track {
                    /*滚动条里面轨道*/
                    background: #007aaa59;
                }
                &::-webkit-scrollbar-corner{
                    background: #007aaa59;
                }
                >div{
                  >span{
                    color: #3E9DD2;
                  }
                }
              }
            }
          }
          a{
            text-align: center;
            font-size: .24rem;
            font-weight: 400;
            color: #45BBF3;
          }
      }
  }
  </style>
